.ql-container.ql-snow {
  min-height: 88px !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-color: var(--chakra-colors-gray-200);
}
.ql-toolbar.ql-snow {
  border-color: var(--chakra-colors-gray-200);
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.ql-size-huge {
  font-size: 30px !important;
  color: inherit;
}
.ql-size-large {
  font-size: 20px !important;
  color: unset;
}
.ql-size-normal {
  font-size: 18px !important;
  color: inherit;
}
.ql-size-small {
  font-size: 14px !important;
  color: inherit;
}
